body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.card {
	background-color: #fff;
	border-radius: 100px;
	box-shadow: #D1C6AD 0px 54px 55px, #D1C6AD 0px -12px 30px, #D1C6AD 0px 4px 6px, #D1C6AD 0px 12px 13px, #D1C6AD 0px -3px 5px;
	transition: all 0.3s ease-in-out;
	padding: 5px 10px 5px 10px;
}

.card:hover {
	box-shadow: #e0b553 0px 54px 55px, #e0b553 0px -12px 30px, #e0b553 0px 4px 6px, #e0b553 0px 12px 13px, #e0b553 0px -3px 5px;
}

.card-text {
	font-size: 1.3rem;
	padding: 0 50px 0 50px;
}

#brand {
	transition: all 0.3s ease-in-out;
}

#brand:hover {
	animation: myAnim 2s ease 0s 1 normal forwards;
}

@media screen and (max-width: 600px) {
	.card-text {
		font-size: 1.1rem;
		padding: 0 20px 0 20px;
	}
}

@keyframes myAnim {
	0% {
		transform: translate(0);
	}

	10% {
		transform: translate(-2px, -2px);
	}

	20% {
		transform: translate(2px, -2px);
	}

	30% {
		transform: translate(-2px, 2px);
	}

	40% {
		transform: translate(2px, 2px);
	}

	50% {
		transform: translate(-2px, -2px);
	}

	60% {
		transform: translate(2px, -2px);
	}

	70% {
		transform: translate(-2px, 2px);
	}

	80% {
		transform: translate(-2px, -2px);
	}

	90% {
		transform: translate(2px, -2px);
	}

	100% {
		transform: translate(0);
	}
}